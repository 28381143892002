<template>
  <b-card>
    <b-row
      align-h="end"
      class="mr-0"
      style="gap: 1rem;"
    >
      <download-em-excel
        :filtro="filtroAtual"
        tipo="CNDs FGTS"
        :api="apiFgts"
      />
      <download-em-lote
        tipo-arquivo="todas as CND's FGTS"
        tipo="Cnd FGTS"
        :filtro="filtroAtual.filtroBy"
        :palavras-filtro="[['Regulares', 'Irregulares', 'Motivo']]"
        @baixar-lote="baixarEmLote"
      />
    </b-row>
    <cnd-fgts-list
      ref="cndFgtsList"
      :botoes-opcoes="botoesOption"
      :visualizacao-opcoes="visualizacao"
      :filtros="filtros"
      :filtros-rapidos="filtrosRapidos"
    >
      <template
        slot="custom"
        slot-scope="{ props} "
      >
        <cnd-situacao
          v-if="props.column.field === 'situacao'"
          :value="props.row[props.column.field]"
          :descricao="props.row['situacaoDesc']"
        />
        <span
          v-if="props.column.field === 'razaoSocial'"
          @click.stop="$refs[`acoes${props.row.id}`].abrirArquivo(props.row)"
        >
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template
        slot="acoes"
        slot-scope="{ props} "
      >
        <cnd-acoes
          :ref="`acoes${props.row.id}`"
          :grid-props="props"
          :api="apiFgts"
          :tipo-cnd="tipoCnd"
          :att-manual="true"
          @atualizar-grid="atualizarGrid"
        />
      </template>
    </cnd-fgts-list>
  </b-card>
</template>

<style lang="scss" scoped>
.cnd-td-acoes {
  width: 7rem;
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.tamanho-cnpj {
  min-width: 194px!important;
}
</style>

<script>

import api from '@/app/cnd-federal/shared/services/api'
import colunas from './shared/components/colunas'

export default {
  name: 'CndFgtsVisualizacao',
  components: {
    CndFgtsList: () => import('@pilar/components/visualizacao-base/VisualizacaoBase.vue'),
    CndAcoes: () => import('@/app/shared/components/cnd-acoes/CndAcoes.vue'),
    CndSituacao: () => import('@/app/shared/components/cnd-situacao/CndSituacao.vue'),
    DownloadEmLote: () => import('@app/download-lote/DownloadEmLote.vue'),
    DownloadEmExcel: () => import('@app/download-excel/DownloadEmExcel.vue'),
  },
  data() {
    return {
      apiFgts: api,
      tipoCnd: 'fgts',
      botoesOption: null,
      linha: null,
      visualizacao: {
        placeholderBusca: 'Buscar por: CNPJ, Razão social, Consultado em, Data de validade e próxima consulta',
        acaoClass: 'col-td-acao-4',
        disabledCheckbox: true,
      },
      filtros: [{ colunas: ['CadastroFederalSemMascara', 'CadastroFederal', 'RazaoSocial', 'UltimaConsulta', 'DataValidade', 'ProximaConsulta'], valor: null }],
      filtrosRapidos: [
        {
          id: 'Regular',
          colunas: ['Situacao'],
          label: 'Regulares',
          valor: '1,2',
        },
        {
          id: 'Irregular',
          colunas: ['Situacao'],
          label: 'Irregulares',
          valor: '3',
        },
        {
          id: 'Motivo',
          colunas: ['Situacao'],
          label: 'Motivo não geração',
          valor: '4,6',
        },
        {
          id: 'EmpregadorNaoCadastrado',
          colunas: ['Situacao'],
          label: 'Empregador não cadastrado',
          valor: '6',
        }],
      criando: true,
      aplicando: false,
      filtroAtual: {},
    }
  },
  created() {
    this.visualizacao.colunas = colunas
    this.visualizacao.evento = this.carregar
  },
  mounted() {
    this.aplicarFiltroQuery()
  },
  updated() {
    this.aplicarFiltroQuery()
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    carregar(pagina, tamanhoPagina, filtro) {
      this.filtroAtual = filtro
      return api.getPaginado(this.tipoCnd, pagina, tamanhoPagina, filtro)
    },
    aplicarFiltroQuery() {
      this.$nextTick(() => {
        const params = this.$route.query
        if (params && this.$refs.cndFgtsList) {
          const filtroRapido = params.filtroRapido
            ? this.filtrosRapidos.find(f => f.id === params.filtroRapido)
            : null
          this.$refs.cndFgtsList.aplicarFiltro(params.busca, filtroRapido)
        }
      })
    },
    baixarEmLote(evt) {
      api.baixaEmLote(this.tipoCnd, this.filtroAtual, evt)
    },
    atualizarGrid() {
      this.$refs.cndFgtsList.buscar()
    },
  },
}
</script>
