import axios from '@axios'

const PREFIX = 'cnd-federais'

export default {

  getPaginado(tipoCnd, page, pageSize, filtros) {
    return axios.post(`${PREFIX}/${tipoCnd}/paged?pagina=${page}&tamanhoPagina=${pageSize}`, filtros)
  },

  getURL(id) {
    return axios.get(`${PREFIX}/${id}/url`)
  },

  updateCnd(id, tipoCnd) {
    return axios.get(`${PREFIX}/${id}/${tipoCnd}/atualizar-cnd`)
  },

  getObservacoes(id, tipoCnd) {
    return axios.get(`${PREFIX}/${tipoCnd}/${id}/observacao`)
  },

  removerObservacao(id) {
    return axios.delete(`${PREFIX}/observacao/${id}/remover`)
  },

  salvarObservacao(observacao) {
    return axios.post(`${PREFIX}/observacao/salvar`, observacao)
  },

  notificaCnd(nomeEmpresa, emailEmpresa, emailCc, id, mensagemAdicional, definirEmailPadrao) {
    return axios.post(`${PREFIX}/${id}/notifica?emailEmpresa=${emailEmpresa}&emailCc=${emailCc}&nomeEmpresa=${nomeEmpresa}&mensagemAdicional=${encodeURIComponent(mensagemAdicional)}&definirEmailPadrao=${definirEmailPadrao}`)
  },

  baixaEmLote(tipoCnd, filtros, nomeArquivo) {
    return axios.post(`${PREFIX}/${tipoCnd}/baixa-lotes?nomeArquivo=${nomeArquivo}`, filtros)
  },

  getURLSituacaoFiscal(id) {
    return axios.get(`${PREFIX}/${id}/url/situacao-fiscal`)
  },

  getPendenciasSituacaoFiscal(id) {
    return axios.get(`${PREFIX}/rfb/${id}/pendencias`)
  },

  baixarEmExcel(tipoCnd, filtros) {
    return axios.post(`${PREFIX}/${tipoCnd}/baixa-excel`, filtros)
  },

  deletarExcel(url, nomeArquivo) {
    axios.post(`${PREFIX}/deletar-excel?url=${url}&nomeArquivo=${nomeArquivo}`)
  },

  notificaHistorico(nomeEmpresa, emailEmpresa, emailCc, mensagemAdicional, definirEmailPadrao, ids) {
    return axios.post(`${PREFIX}/notifica-historico?emailEmpresa=${emailEmpresa}&emailCc=${emailCc}&nomeEmpresa=${nomeEmpresa}&mensagemAdicional=${encodeURIComponent(mensagemAdicional)}&definirEmailPadrao=${definirEmailPadrao}`, ids)
  },

  getHistorico(tipoCnd, id, filtros) {
    return axios.post(`${PREFIX}/${tipoCnd}/historico?empresaId=${id}`, filtros)
  },

  baixarSelecionados(ids) {
    return axios.post(`${PREFIX}/baixar-selecionadas`, ids)
  },

  GetUrlWhatsApp(ids) {
    return axios.post(`${PREFIX}/url-whatsapp`, ids)
  },
}
